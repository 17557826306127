import {Component, OnInit} from '@angular/core';
import {ModalController, NavParams} from '@ionic/angular';
import {CommonService} from '../../../services/common.service';

@Component({
    selector: 'app-take-picture',
    templateUrl: './take-picture.page.html',
    styleUrls: ['./take-picture.page.scss'],
})
export class TakePicturePage implements OnInit {

    image: any;

    constructor(private modalController: ModalController,
                private commonService: CommonService,
                private navParams: NavParams) {
    }


    ngOnInit() {
        // console.table(this.navParams);
        this.image = this.navParams.data.image;
        this.image.name = 'Name';
        this.image.description = 'Beschreibung';
    }

    async returnModal() {
        // const onClosedData = 'Wrapped Up!';
        const imageData = {
            status: 'ok',
            image: this.image
        };
        await this.modalController.dismiss(imageData);
    }

    async dismiss() {
        // const onClosedData = 'Wrapped Up!';
        const imageData = {
            status: 'dismiss'
        };
        await this.modalController.dismiss(imageData);
    }
}
