import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class CommonService {

    constructor() {
    }

    public sliderOptions = {
        zoom: false,
        slidesPerView: 2.5,
        centeredSlides: true,
        spaceBetween: 5
    };

    selectTextOnFocus(event) {
        event.target.firstChild.select();
    }

    // this function takes hours as float and returns a formate string with hours and minutes
    public floatToDuration(durationFloat) {

        const hours = Math.floor((durationFloat * 60 * 60) / 3600);
        const minutes = Math.floor(((durationFloat * 60 * 60) - (hours * 3600)) / 60);
        const seconds = (durationFloat * 60 * 60) - (hours * 3600) - (minutes * 60);

        let hoursString = hours.toString();
        let minutesString = minutes.toString();
        let secondsString = seconds.toString();

        if (hours < 10) {
            hoursString = '0' + hours;
        }
        if (minutes < 10) {
            minutesString = '0' + minutes;
        }
        if (seconds < 10) {
            secondsString = '0' + seconds;
        }
        return hoursString + ':' + minutesString;
    }

    public dateToString(date: Date) {
        const dd = String(date.getDate()).padStart(2, '0');
        const mm = String(date.getMonth() + 1).padStart(2, '0'); // January is 0!
        const yyyy = date.getFullYear();
        const H = String(date.getHours()).padStart(2, '0');
        const M = String(date.getMinutes()).padStart(2, '0');
        const S = String(date.getSeconds()).padStart(2, '0');
        // const dateAsString =  yyyy + '-' + mm + '-' + dd + 'T' + H + ':' + M + ':' + S + '+01:00';
        const dateAsString = yyyy + '-' + mm + '-' + dd + 'T' + H + ':' + M + ':' + S;
        return dateAsString;
    }

    Sleep(milliseconds) {
        return new Promise(resolve => setTimeout(resolve, milliseconds));
    }

    public servertimePlusOffsetToDate(serverTime, timezoneOffset) {
        const offsetHours = Number(timezoneOffset) / 100;
        // tslint:disable-next-line:max-line-length
        const dateStringWithOffset = serverTime + timezoneOffset.substring(0, 3) + ':00';
        const newDate = new Date(Date.parse(dateStringWithOffset));
        newDate.setHours(newDate.getHours() + offsetHours);
        return newDate;
    }
}

