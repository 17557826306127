import {Component, OnInit} from '@angular/core';
import {ModalController, NavParams} from '@ionic/angular';
import {CommonService} from '../../../services/common.service';
import {PartnerService} from '../../../services/partner.service';

@Component({
    selector: 'app-edit-partner',
    templateUrl: './edit-partner.page.html',
    styleUrls: ['./edit-partner.page.scss'],
})
export class EditPartnerPage implements OnInit {
    partner: any = {};

    constructor(private commonService: CommonService,
                private modalController: ModalController,
                public partnerService: PartnerService,
                private navParams: NavParams) {
    }

    ngOnInit() {
        // console.table(this.navParams);
        this.partner = this.navParams.data.partner;
    }

    updatePartner() {
        this.partnerService.updatePartner(this.partner).then((partnerData: any) => {
            this.partner = partnerData;
            this.returnModal();
        });
    }

    async returnModal() {
        const modalData = {
            status: 'ok',
            partner: this.partner
        };
        await this.modalController.dismiss(modalData);
    }

    async dismiss() {
        // const onClosedData = 'Wrapped Up!';
        const imageData = {
            status: 'dismiss'
        };
        await this.modalController.dismiss(imageData);
    }
}
