import {Injectable} from '@angular/core';
import {Storage} from '@ionic/storage';

@Injectable({
    providedIn: 'root'
})
export class DataStorageService {

    public keyValues: any = [];

    constructor(private storage: Storage) {
        this.storage.forEach((value: any, key: string, iterationNumber: number) => {
            this.keyValues[key] = value;
        });
    }

    storeData(key, data) {
        // set a key/value
        return new Promise(resolve => {
            this.storage.set(key, data).then((res) => {
                this.keyValues[key] = data;
                resolve(res);
            });
        });
    }

    loadData(key) {
        return new Promise(resolve => {
            this.storage.get(key).then((data) => {
                resolve(data);
            });
        });
    }

    clearStorage() {
        return new Promise(resolve => {

            this.keyValues = [];
            this.storage.clear().then(() => {
                resolve();
            });
        });

    }
}



