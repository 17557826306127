import {Injectable} from '@angular/core';
import {ToastController} from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class ToastService {
    public position: any = {
        top: 'top',
        bottom: 'bottom',
        middle: 'middle'
    };

    public colorStyle: any = {
        primary: 'primary',
        secondary: 'secondary',
        tertiary: 'tertiary',
        success: 'success',
        warning: 'warning',
        danger: 'danger',
        light: 'light',
        medium: 'medium',
        dark: 'dark',
    };

    duration: any = 5000;

    constructor(public toastCtrl: ToastController) {
    }

    async showToast(message: string, position: string = 'bottom', colorStyle: string = 'dark') {

        const toast = await this.toastCtrl.create({
            // header: 'Toast header',
            message,
            animated: true,
            // translucent: true,
            duration: this.duration,
            position: this.position[position], // position,
            color: colorStyle,
            cssClass: 'toast-alert-class', // 'toast-default-class'
            mode: 'ios',
            showCloseButton: true,
            closeButtonText: 'Schließen',

        }).then((obj) => {
            obj.present();
        });
        // await toast.present();

        /*
         global.scss

ion-toast.toast-mess {
    opacity: 0.9;
    text-align: center;
    color: var(--ion-color-light);
    --background: var(--ion-color-dark);
}*/
    }
}
