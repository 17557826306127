import {AuthGuard} from './guards/auth.guard';
import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';

const routes: Routes = [
    {path: '', redirectTo: 'login', pathMatch: 'full'},
    {
        path: 'login',
        loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
    },
    {
        path: 'home',
        loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule)
    },

    {
        path: 'opportunities',
        loadChildren: () => import('./pages/opportunity-pages/opportunities/opportunities.module').then(m => m.OpportunitiesPageModule)
    },
    {
        path: 'projects',
        loadChildren: () => import('./pages/project-pages/projects/projects.module').then(m => m.ProjectsPageModule)
    },
    {
        path: 'notifications',
        loadChildren: () => import('./pages/notification-pages/notifications/notifications.module').then(m => m.NotificationsPageModule)
    },

    {
        path: 'timeRecording',
        loadChildren: () => import('./pages/time-recording-pages/time-recording/time-recording.module').then(m => m.TimeRecordingPageModule)
    },
    {
        path: 'profile',
        loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfilePageModule)
    },
    {
        path: 'partner-create-edit',
        // tslint:disable-next-line:max-line-length
        loadChildren: () => import('./pages/opportunity-pages/partner-create-edit/partner-create-edit.module').then(m => m.PartnerCreateEditPageModule)
    },
    {
        path: 'window-create-edit',
        // tslint:disable-next-line:max-line-length
        loadChildren: () => import('./pages/window-pages/window-create-edit/window-create-edit.module').then(m => m.WindowCreateEditPageModule)
    },
    {
        path: 'window-edit',
        loadChildren: () => import('./pages/window-pages/window-edit/window-edit.module').then(m => m.WindowEditPageModule)
    },
    {
        path: 'window-rail-profiles',
        // tslint:disable-next-line:max-line-length
        loadChildren: () => import('./pages/window-pages/window-rail-profiles/window-rail-profiles.module').then(m => m.WindowRailProfilesPageModule)
    },
    {
        path: 'window-joints',
        loadChildren: () => import('./pages/window-pages/window-joints/window-joints.module').then(m => m.WindowJointsPageModule)
    },
    {
        path: 'window-catches',
        loadChildren: () => import('./pages/window-pages/window-catches/window-catches.module').then(m => m.WindowCatchesPageModule)
    },
    {
        path: 'window-blinds',
        loadChildren: () => import('./pages/window-pages/window-blinds/window-blinds.module').then(m => m.WindowBlindsPageModule)
    },
    {
        path: 'window-drives-woodparts',
        // tslint:disable-next-line:max-line-length
        loadChildren: () => import('./pages/window-pages/window-drives-woodparts/window-drives-woodparts.module').then(m => m.WindowDrivesWoodpartsPageModule)
    },
    {
        path: 'window-glass',
        loadChildren: () => import('./pages/window-pages/window-glass/window-glass.module').then(m => m.WindowGlasPageModule)
    },
    {
        path: 'logout',
        loadChildren: () => import('./pages/logout/logout.module').then(m => m.LogoutPageModule)
    },
    {
        path: 'check-in-out',
        loadChildren: () => import('./pages/time-recording-pages/check-in-out/check-in-out.module').then(m => m.CheckInOutPageModule)
    },
    {
        path: 'project-details',
        loadChildren: () => import('./pages/project-pages/project-details/project-details.module').then(m => m.ProjectDetailsPageModule)
    },
    {
        path: 'window-workorders',
        loadChildren: () => import('./pages/window-pages/window-workorders/window-workorders.module').then(m => m.WindowDetailsPageModule)
    },
    {
        path: 'take-picture',
        loadChildren: () => import('./pages/modals/take-picture/take-picture.module').then(m => m.TakePicturePageModule)
    },
    {
        path: 'edit-partner',
        loadChildren: () => import('./pages/modals/edit-partner/edit-partner.module').then(m => m.EditPartnerPageModule)
    },
    {
        path: 'sick-leave',
        loadChildren: () => import('./pages/time-recording-pages/sick-leave/sick-leave.module').then(m => m.SickLeavePageModule)
    },
    {
        path: 'payed-leave',
        loadChildren: () => import('./pages/time-recording-pages/payed-leave/payed-leave.module').then(m => m.PayedLeavePageModule)
    },
    {
        path: 'search-partner',
        loadChildren: () => import('./pages/modals/search-partner/search-partner.module').then(m => m.SearchPartnerPageModule)
    },
    {
        path: 'edit-picture',
        loadChildren: () => import('./pages/modals/edit-picture/edit-picture.module').then(m => m.EditPicturePageModule)
    },
    {
        path: 'leave-edit',
        loadChildren: () => import('./pages/time-recording-pages/leave-edit/leave-edit.module').then(m => m.LeaveEditPageModule)
    },
    {
        path: 'attendance-edit',
        // tslint:disable-next-line:max-line-length
        loadChildren: () => import('./pages/time-recording-pages/attendance-edit/attendance-edit.module').then(m => m.AttendanceEditPageModule)
    },
    {
        path: 'edit-opportunity',
        loadChildren: () => import('./pages/modals/edit-opportunity/edit-opportunity.module').then(m => m.EditOpportunityPageModule)
    },
    {
        path: 'bulk-workorders',
        loadChildren: () => import('./pages/window-pages/bulk-workorders/bulk-workorders.module').then(m => m.BulkWorkordersPageModule)
    },
    {
        path: 'sale-order-create',
        // tslint:disable-next-line:max-line-length
        loadChildren: () => import('./pages/opportunity-pages/sale-order-create/sale-order-create.module').then(m => m.SaleOrderCreatePageModule)
    },
    {
        path: 'search-sale-order',
        loadChildren: () => import('./pages/modals/search-sale-order/search-sale-order.module').then(m => m.SearchSaleOrderPageModule)
    },
    {
        path: 'search-vehicle',
        loadChildren: () => import('./pages/modals/search-vehicle/search-vehicle.module').then(m => m.SearchVehiclePageModule)
    },
    {
        path: 'switch-cost-center',
        loadChildren: () => import('./pages/modals/switch-cost-center/switch-cost-center.module').then(m => m.SwitchCostCenterPageModule)
    },
    {
        path: 'switch-sale-order',
        loadChildren: () => import('./pages/modals/switch-sale-order/switch-sale-order.module').then(m => m.SwitchSaleOrderPageModule)
    },
    {
        path: 'overtime',
        loadChildren: () => import('./pages/time-recording-pages/overtime-request/overtime-request.module').then(m => m.OvertimePageModule)
    },
    {
        path: 'overtime-request-edit',
        // tslint:disable-next-line:max-line-length
        loadChildren: () => import('./pages/time-recording-pages/overtime-request-edit/overtime-request-edit.module').then(m => m.OvertimeEditPageModule)
    },
    {
        path: 'project-management',
        // tslint:disable-next-line:max-line-length
        loadChildren: () => import('./pages/project-management-pages/project-management/project-management.module').then(m => m.ProjectManagementPageModule)
    },
    {
        path: 'project-windows',
        // tslint:disable-next-line:max-line-length
        loadChildren: () => import('./pages/project-management-pages/project-windows/project-windows.module').then(m => m.ProjectWindowsPageModule)
    },

];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
