import {Injectable} from '@angular/core';
import {RequestService} from './request.service';

@Injectable({
    providedIn: 'root'
})
export class PartnerService {
    public partnerId: any = -1;
    public partner: any = {};
    public partners: any = [];
    apiUrl = 'api/window/partner/partner/';

    NO_UPDATE_FIELDS = [];

    constructor(public requestService: RequestService) {
    }

    getUrl(url) {
        return this.apiUrl + url;
    }

    getPartner(partnerId) {
        return new Promise(resolve => {
            this.requestService.get(this.getUrl(String(partnerId) + '/get')).then(data => {
                resolve(data);
            });
        });
    }

    getPartners() {
        return new Promise(resolve => {
            this.requestService.get(this.getUrl('search?regex=*')).then(data => {
                resolve(data);
            });
        });
    }

    searchPartner(regex) {
        return new Promise(resolve => {
            this.requestService.get(this.getUrl('search?regex=' + regex)).then(data => {
                resolve(data);
            });
        });
    }

    creatPartner(partner) {
        return new Promise(resolve => {
            this.requestService.post(this.getUrl('create'), partner).then(data => {
                resolve(data);
            });
        });
    }


    _updatePartner(partner) {
        return new Promise(resolve => {
            this.requestService.post(this.getUrl(String(partner.id) + '/update'), partner).then(data => {
                resolve(data);
            });
        });
    }

    updatePartner(partner) {
        this.partner = partner;

        // need to fix some values because the rest api ist strict about them
        // if (!this.window.coating) {
        //     delete this.window.coating;
        // }

        this.NO_UPDATE_FIELDS.forEach(noUpdateField => delete this.partner[noUpdateField]);

        return new Promise(resolve => {
            this._updatePartner(partner).then(data => {
                this.partner = data;
                this.partnerId = this.partner.id;

                this.getPartner(this.partnerId).then(partnerData => {
                    this.partner = partnerData;

                    resolve(this.partner);
                });
            });
        });
    }
}
