import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';

import {IonicSelectableModule} from 'ionic-selectable';
// import {SecureStorage, SecureStorageObject} from '@ionic-native/secure-storage/ngx';


import {IonicStorageModule} from '@ionic/storage';
import {BarcodeScanner} from '@ionic-native/barcode-scanner/ngx';
import {Camera, CameraOptions} from '@ionic-native/camera/ngx';

import {TakePicturePageModule} from './pages/modals/take-picture/take-picture.module';
import {EditPartnerPageModule} from './pages/modals/edit-partner/edit-partner.module';
import {HttpClientModule} from '@angular/common/http';
import {AppVersion} from '@ionic-native/app-version/ngx';


// import {HomePage} from './pages/home/home.page';
// import {HomePageModule} from './pages/home/home.module';
// import xmlrpc from 'xmlrpc';
// import url from 'url';
// import * as Odoo from 'odoo-xmlrpc';
// import { Ng6OdooRPCService } from 'angular6-odoo-jsonrpc';
// import { HttpClient, HttpClientModule, HttpHeaders } from '@angular/common/http';


@NgModule({
    declarations: [AppComponent],
    entryComponents: [],
    imports: [BrowserModule, HttpClientModule, IonicModule.forRoot(),
        IonicStorageModule.forRoot(),
        IonicSelectableModule,
        AppRoutingModule], // , HomePageModule],
    providers: [
        // HttpClientModule, // test ???
        StatusBar,
        SplashScreen,
        BarcodeScanner,
        AppVersion,
        TakePicturePageModule,
        EditPartnerPageModule,
        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
        //  SecureStorage
        Camera,
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
